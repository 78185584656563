<template lang="html">
  <div class="AccessPermissionsModal">
    <div class="AccessPermissionsModal--Modal">
      <div class="AccessPermissionsModal--Header">
        <h1>{{$t('videochat_permission')}}</h1>
        <div class="AccessPermissionsModal--Close" v-on:click="$emit('close')">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="AccessPermissionsModal--Content">
        <p v-html="$t('chat_user_want_to_join',{'testerName':testerName})"></p>
        <div class="AccessPermissionsModal--Actions">
          <FocusButton type="button" name="button" v-on:click="$emit('grant-access',{accessGranted:true, userName: userName})">{{ $t('chat_user_allow_access') }}</FocusButton>
          <FocusButton color="red" type="button" name="button" v-on:click="$emit('grant-access',{accessGranted:false, userName: userName})">{{ $t('chat_user_deny_access') }}</FocusButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FocusButton from './FocusButton.vue'

export default {
  name: 'VideochatComments',
  components: {
    FocusButton
  },
  props:{
    userName:{
      type: String,
      default: 'UserName1000'
    },
    testerName:{
      type: String,
      default: 'TesterName1000'
    }
  },
}
</script>
<style lang="scss" scoped>
.AccessPermissionsModal{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background: rgba(0,0,0,0.5);
  .FocusButton{
    font-size: 15px;
  }
  &--Modal{
    width: 500px;
    background: white;
    padding: 10px;
  }
  &--Header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  &--Content{
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  &--Actions{
    display: flex;
    gap: 25px;
    justify-content: flex-end;
    width: 100%;
  }
  &--Close{
    transition: 0.1s;
    cursor: pointer;
    &:hover{
      opacity: 0.5;
    }
    span:nth-child(1){
      transform: rotate(45deg) translate(-1px, -3px);
    }
    span:nth-child(2){
      transform: rotate(135deg) translate(-12px, 0px);
    }
    span{
      display: flex;
      width: 20px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: var(--accent-color);
      border-radius: 3px;
      z-index: 1;
      transform-origin: 5px 0px;
    }
  }
  h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.01em;
    color: #404040;
  }
}
</style>
