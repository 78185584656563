
import { defineComponent } from 'vue'
import WatFocus from '~/components/WatFocus/WatFocus.vue'
import {
    IonPage,
    IonContent,
} from '@ionic/vue'

export default defineComponent({
    name: 'WatFocusExecution',
    components:{
        IonPage,
        IonContent,
        WatFocus
    }
})
