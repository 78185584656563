<template lang="html">
  <div class="VideochatComments">
      <h1>Anotaciones</h1>
      <div class="VideochatComments--Content">
        <span>Minuto: {{currentTimeFromStart}}</span>
        <textarea name="name" rows="8" cols="80" v-model="comment"></textarea>
        <FocusButton type="button" name="button" v-on:click="$emit('save-comment',comment)">Guardar anotación</FocusButton>
      </div>
  </div>
</template>
<script>
import FocusButton from './FocusButton.vue'

export default {
  name: 'VideochatComments',
  components: {
    FocusButton
  },
  props:{
    currentTimeFromStart:{
      type: String,
      default:''
    }
  },
  data(){
    return{
      comment:''
    }
  }
}
</script>
<style lang="scss" scoped>
.VideochatComments{
  background: #F5F5F5;
  border: 1px solid #D3D3D3;
  padding: 15px;
  height: 100%;
  .FocusButton{
    font-size: 15px;
  }
  &--Content{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #E1E1E1;
    span{
      background: #f2f2f2;
      padding: 5px;
      color: var(--primary-color);
      font-family: 'Roboto Condensed';
      border-radius: 6px;
    }
  }
  h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.01em;
    color: #404040;
    margin-bottom: 10px;
  }
  textarea{
    resize: none;
    width: 100%;
    border: 1px solid lightgray;
    padding: 10px;
  }
}
</style>
